import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import Swipe from 'react-easy-swipe';
import styled from 'styled-components';
import arrow from '../../images/arrow.svg';
import Card from './Card';

const Carousel = ({ component: { heading, slides } }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [carouselWidth, setWidth] = useState(870);
  const carouselDom = useRef();

  const prevSlider = () => {
    setCurrentSlide(current =>
      current === 0 ? (slides?.length || 1) - 1 : current - 1
    );
  };
  const nextSlider = () => {
    setCurrentSlide(current =>
      current === (slides?.length || 1) - 1 ? 0 : current + 1
    );
  };
  const goSlider = index => {
    setCurrentSlide(index);
  };
  const handleResize = () => {
    if (carouselDom.current) {
      setWidth(carouselDom.current.offsetWidth);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <CarouselWrapper>
      <Container>
        {heading && <h3>{heading}</h3>}
        <Swipe
          allowMouseEvents
          onSwipeLeft={nextSlider}
          onSwipeRight={prevSlider}
        >
          <div
            className="carousel-container"
            style={{
              transform: `translateX(-${currentSlide *
                (carouselWidth + 30)}px)`,
            }}
          >
            {slides?.map(slide => (
              <Card slide={slide} key={slide.internalName} />
            ))}
          </div>
        </Swipe>
        <div className="dots-container clearfix" ref={carouselDom}>
          <div className="dots float-left">
            {Array(slides?.length || 0)
              .fill()
              .map((_, index) => (
                <span
                  className={`carousel-dot ${currentSlide === index &&
                    'active'}`}
                  key={`dot-${index + 1}`}
                  onClick={() => goSlider(index)}
                  role="button"
                  aria-label={`Page ${index + 1}`}
                  tabIndex={0}
                >
                  &nbsp;
                </span>
              ))}
          </div>
          <div className="controller float-right">
            <span
              className="arrow arrow-left"
              onClick={() => prevSlider()}
              role="button"
              aria-label="Previous"
              tabIndex={0}
            >
              <img src={arrow} alt="arrow" />
            </span>
            <span
              className="arrow arrow-right"
              onClick={() => nextSlider()}
              role="button"
              aria-label="Next"
              tabIndex={0}
            >
              <img src={arrow} alt="arrow" />
            </span>
          </div>
        </div>
      </Container>
    </CarouselWrapper>
  );
};

export default Carousel;

export const CarouselWrapper = styled.div`
  padding: 100px 0;
  background: #171e2a;
  color: #fff;
  overflow: hidden;

  @media (max-width: 768px) {
    padding-top: 48px;
  }

  h3 {
    margin-bottom: 40px;
    font-weight: bold;
  }

  .carousel-container {
    display: flex;
    transition: all 0.6s;
    width: 100%;
    a {
      max-width: 100%;
    }
    div {
      max-width: 100%;
    }
  }

  .dots-container {
    width: 870px;
    max-width: 100%;
    margin-top: 22px;

    .carousel-dot {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #fff;
      opacity: 0.5;
      margin-right: 16px;
      cursor: pointer;

      &.active {
        opacity: 1;
      }
    }

    .controller {
      span {
        cursor: pointer;

        &.arrow {
          transition: opacity 0.2s ease-in-out 0s;

          &:hover {
            opacity: 0.5;
          }
        }

        &.arrow-left {
          margin-right: 8px;

          img {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
`;
